// this context will be in charge of metrics dashboard and any charts we decide to import

import React, { createContext, useReducer, useContext } from "react";
import { UserContext } from "./UserContext";
import MongoService from "../services/MongoService";
import { parseMetrics } from "../utils/parseMetrics";
import { calcMetrics } from "../pages/Admin/MetricsUtils/calcMetrics";
import { toast } from "react-toastify";

export const MetricsContext = createContext();

const initialState = {
	metricsByMonth: [],
	calculatedMetrics: [],
};

const metricsReducer = (state, action) => {
	switch (action.type) {
		case "SET_METRICS":
			return { ...state, metricsByMonth: action.payload };
		case "UPDATE_METRIC":
			const updatedMetrics = state.metricsByMonth.map((metric) => {
				if (metric.month === action.payload.month) {
					return { ...metric, ...action.payload };
				}
				return metric;
			});
			return { ...state, metricsByMonth: updatedMetrics };
		default:
			return state;
	}
};

export const MetricsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(metricsReducer, initialState);
	const { user, isAdmin } = useContext(UserContext);

	async function getMetrics() {
		const metrics = await MongoService.getMetrics(user);
		console.log("🚀 ~ getMetrics ~ metrics:", metrics);
		var parsedMetrics = await parseMetrics(metrics);

		let completeMetrics = await parsedMetrics.map(async (metric, index) => {
			var calculatedMetrics = await calcMetrics(metric);
			return { ...metric, ...calculatedMetrics };
		});

		parsedMetrics = await Promise.all(completeMetrics);

		dispatch({ type: "SET_METRICS", payload: parsedMetrics });
	}

	async function updateMetric(data) {
		const metrics = await calcMetrics(data);
		const newMetrics = { ...data, ...metrics };
		console.log("🚀 ~ updateMetric ~ newMetrics:", newMetrics);
		dispatch({ type: "UPDATE_METRIC", payload: newMetrics });
	}

	async function calculateMetrics(data) {
		var metrics = await calcMetrics(data);
		return metrics;
		// calculate the metrics for one specific month based on the month value
		// and update the state with the new metrics
	}

	async function saveMetricsToDb() {
		var savedMetrics = MongoService.saveMetrics(user, state.metricsByMonth);
		if (savedMetrics) {
			toast.success("Metrics saved successfully! 🚀");
		}
		return savedMetrics;
	}

	const values = {
		state,
		dispatch,
		getMetrics,
		updateMetric,
		calculateMetrics,
		saveMetricsToDb,
	};

	return (
		<MetricsContext.Provider value={values}>{children}</MetricsContext.Provider>
	);
};

export default MetricsContext;
