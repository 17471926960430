import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { NavbarComponent } from "./components/NavbarComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import ScrollButton from "./components/ScrollToTop";
import { CsvAccept } from "./pages/CsvImport/CsvAccept";
import { InsertDocument } from "./pages/CsvImport/InsertDocument";
import { Replen } from "./pages/CsvImport/Replen";
import OfficeCatalog from "./pages/OfficeCollection/OfficeCatalog";
import AdminCatalog from "./pages/Admin/AdminCatalog";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";
import QuarterlyReport from "./pages/Admin/QuarterlyReport";
import IdleTimeOutHandler from "./components/IdleTimeOutHandler";
import MetricsDashboard from "./pages/Admin/MetricsDashboard";

function App() {
	const [isActive, setIsActive] = useState(true);
	const [isLogout, setIsLogout] = useState(false);

	return (
		<div className="App bg-turtle_dark spaceMono">
			<IdleTimeOutHandler
				onActive={() => setIsActive(true)}
				onIdle={() => setIsActive(false)}
				onLogout={() => setIsLogout(true)}
				timeOutInterval={28800000}
			/>
			<NavbarComponent />
			<Routes>
				<Route path="login" element={<Login />} />
				<Route element={<PrivateRoute />}>
					<Route path="/" element={<OfficeCatalog />} />
					<Route path="import" element={<CsvAccept />} />
					<Route path="singleDoc" element={<InsertDocument />} />
					<Route path="replen" element={<Replen />} />
					<Route path="masterView" element={<AdminCatalog />} />
					<Route path="metrics" element={<MetricsDashboard />} />
					<Route path="officeView" element={<OfficeCatalog />} />
					<Route path="quarterlyReport" element={<QuarterlyReport />} />
				</Route>
			</Routes>
			<ToastContainer />
			<ScrollButton />
		</div>
	);
}

export default App;
