import { React, useState, useContext } from "react";
import { useCSVReader } from "react-papaparse";
import MongoContext from "../../context/MongoContext";
import calculateTaxesPaid from "./CalculateTaxesPaid";
import calculateTotalCost from "./CalculateTotalCost";
import { toast } from "react-toastify";

export function CsvAccept() {
	const { CSVReader } = useCSVReader();
	const { intoOfficeCollection } = useContext(MongoContext);
	const [array, setArray] = useState([]);

	function convertKeys(objArray, keyMap) {
		objArray.forEach((obj) => {
			Object.keys(keyMap).forEach((oldKey) => {
				if (obj.hasOwnProperty(oldKey)) {
					const newKey = keyMap[oldKey];
					obj[newKey] = obj[oldKey];
					delete obj[oldKey];
				}
			});
		});
		var convertedArray = maxAndMsku(objArray);

		return convertedArray;
	}
	// generate the MSKU from the date and initials
	function generateId(purchaseDate, initials, list, unitCost) {
		if (unitCost !== "") {
			// remove dollar sign from unit cost
			unitCost = unitCost.slice(1, unitCost.length);
			// round to nearest dollar
			unitCost = Math.round(unitCost);
		}
		if (list) {
			return (
				list +
				"-" +
				purchaseDate.replaceAll("/", ".") +
				"-" +
				initials +
				"-" +
				unitCost +
				"-" +
				Math.floor(Math.random() * 9000) +
				1
			);
		} else {
			return (
				"RAO-" +
				purchaseDate.replaceAll("/", ".") +
				"-" +
				initials +
				"-" +
				unitCost +
				"-" +
				Math.floor(Math.random() * 9000) +
				1
			);
		}
	}

	// sometimes the notes field has text in it, parse that out
	function parseNotesField(notes) {
		// use regex to parse separate min price from notes
		const regex = /\d+(\.\d+)?[^ ]*/;

		// run the regex on the notes field
		const matches = notes.match(regex);
		// if there is a match, return the min price and the notes
		if (matches !== null) {
			return {
				max:
					(parseFloat(matches[0]) + 30).toFixed(2) === ""
						? ""
						: (parseFloat(matches[0]) + 30).toFixed(2),
				min:
					parseFloat(matches[0]).toFixed(2) === ""
						? ""
						: parseFloat(matches[0]).toFixed(2),
				notes:
					notes.slice(matches[0].length + 1, notes.length) === ""
						? ""
						: notes.slice(matches[0].length + 1, notes.length),
			};
		} else {
			return {
				min: "",
				notes: "",
				max: "",
			};
		}
	}
	// add the extra fields to array
	// check for existing MSKU, add one if not present
	// modBy field will always be admin at this point
	function maxAndMsku(objArray) {
		// console.log(objArray);
		const newArray = objArray.map((obj) => ({
			amzProductTitle: obj.amzProductTitle,
			amzLink: `http://www.amazon.com/exec/obidos/ASIN/${obj.asin}`,
			productLink: obj.productLink,
			supplierLink: obj.supplierLink,
			attributes: obj.attributes,
			upc: obj.upc,
			asin: obj.asin,
			qty: parseInt(obj.qty),
			unitCost: obj.unitCost.replace("$", ""),
			totalBuyPrice: calculateTotalCost(obj.qty, obj.unitCost),
			supplier: obj.supplier,
			date: obj.date ? obj.date : obj.purchasedDate,
			min: parseNotesField(obj.notes).min,
			max: parseNotesField(obj.notes).max,
			initials: obj.initials,
			msku: generateId(obj.date, obj.initials, obj.list, obj.unitCost),
			unitsReceived: 0,
			unitsSDR: 0,
			salesTaxPaid: calculateTaxesPaid(obj.taxPercent, obj.unitCost, obj.qty),
			notes: parseNotesField(obj.notes).notes,
			warehouse: "VENUS",
			list: obj.list ? obj.list : "RAO",
			modBy: "Admin",
		}));
		return newArray;
	}

	var convertTo = {
		source: "supplier",
		purchased: "date",
		cost: "unitCost",
		quantity: "qty",
		title: "amzProductTitle",
		shopper: "initials",
		size: "attributes",
		"tax-percentage-of-cost": "taxPercent",
	};

	// insert into office collection
	async function IntoCollection() {
		try {
			const inserted = await intoOfficeCollection(array);
			console.log(inserted);
		} catch (error) {
			toast.error("Error inserting into collection");
			console.error(error);
		}
	}

	// set skipEmptyLines to greedy to avoid populating empty rows from CSV
	const csvConfig = {
		header: true,
		skipEmptyLines: "greedy",
	};
	// grabs the keys from each object to set the headers
	const headerKeys = Object.keys(Object.assign({}, ...array));

	return (
		<>
			<div>
				<CSVReader
					config={csvConfig}
					onUploadAccepted={(results) => {
						console.log(
							"🚀 ~ file: CsvAccept.js:208 ~ CsvAccept ~ results:",
							results.data
						);
						var convertedArray = convertKeys(results.data, convertTo);
						setArray(convertedArray);
						// console.log(array);
					}}>
					{({
						getRootProps,
						acceptedFile,
						ProgressBar,
						getRemoveFileProps,
					}) => (
						<>
							<div className="d-flex flex-row justify-content-center my-4">
								<button
									type="button"
									{...getRemoveFileProps()}
									onClick={() => setArray([])}
									className="btn btn-danger p-2">
									Remove
								</button>
								<button
									type="button"
									className="btn btn-primary p-2 mx-1"
									{...getRootProps()}>
									Browse file
								</button>
								{/* <button
									className="btn btn-turtle_4 mx-1"
									type="button"
									onClick={() => navigate("/import/replen")}>
									Single Doc
								</button> */}
								<div className="text-center border border-dark border-solid border-3 p-3 w-25">
									{acceptedFile && acceptedFile.name}
								</div>
							</div>
							<div className="d-flex flex-row mb-10 justify-content-center">
								<button
									type="button"
									className="btn btn-turtle_4 p-2 mx-1"
									onClick={IntoCollection}>
									Submit
								</button>
							</div>
							<ProgressBar />
						</>
					)}
				</CSVReader>
				<div className="container-xxl  bg-white">
					{array.map((item) => (
						<div className="my-2 border border-solid rounded-3">
							<div className="row p-2">
								<div className="col-8 col-lg-10 text-start mt-1">
									<span className="fw-bold">{item.amzProductTitle}</span>
								</div>
								<div className="col-4 col-lg-2 mt-1 text-end">{item.date}</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">UPC:</span>
									{item.upc}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">ATTR:</span>
									{item.attributes}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">U.COST:</span>
									{item.unitCost}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">BUY PRICE:</span>
									{item.totalBuyPrice}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">ASIN:</span>
									<a href={item.amzLink}>{item.asin}</a>
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">QTY:</span>
									{item.qty}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">TAX:</span>
									{item.salesTaxPaid}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">WHSE:</span>
									{item.warehouse}
								</div>
								<div className="col-12 col-lg-6 text-start mt-1">
									<span className="text-secondary">MSKU:</span>
									{item.msku}
								</div>
								<div className="col-12 col-lg-6 text-start mt-1">
									<span className="text-secondary">SUPPLIER:</span>
									{item.supplier}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">MIN:</span>
									{item.min}
								</div>
								<div className="col-6 col-lg-3 text-start mt-1">
									<span className="text-secondary">MAX:</span>
									{item.max}
								</div>
							</div>
						</div>
					))}
				</div>
				{/* <div className="table-responsive mt-3">
					<table className="table">
						<thead>
							<tr key={"header"}>
								{headerKeys.map((key) => (
									<th scope="col">{key}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{array.map((item) => (
								<tr key={item.id}>
									{Object.values(item).map((val) => (
										<td>{val}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div> */}
				<div className="container-sm text-wrap text-break my-4">
					<p className="text-sm-start">
						* To ensure that all columns work as expected, do not mix data types
						within columns, if a column is a numerical field, only include
						numbers in the field. This does not include any columns dedicated to
						identifying a product
					</p>
				</div>
			</div>
		</>
	);
}
