import { React, useState, useContext } from "react";
import { MongoContext } from "../../context/MongoContext";
import CondensedTabView from "../../components/CondensedTabView";
import { Button, Form, Col, Row, Label, Input, Container } from "reactstrap";
/**
 * @param {array} mongoInsert - the array containing the data to insert into the col
 * @returns - a document insert id, IF the insert was successful
 */

export const Replen = () => {
	const [document, setDocument] = useState({
		amzProductTitle: "",
		amzLink: "",
		upc: "",
		asin: "",
		qty: Number(0),
		unitCost: "",
		unitPrice: "",
		supplier: "",
		date: "",
		min: "",
		max: "",
		initials: "",
		msku: "",
		unitsReceived: Number(0),
		unitsSDR: Number(0),
		salesTaxPaid: "",
		notes: "",
		warehouse: "",
		list: "",
	});
	const [replen, setReplen] = useState(false);
	const [results, setResults] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const { intoOfficeCollection, replenQuery } = useContext(MongoContext);

	// TODO: change this to work with useReducer
	const handleChange = (event) => {
		setDocument({ ...document, [event.target.name]: event.target.value });
	};

	// on submit, we will calculate unitPrice based off of the unitCost
	const handleSubmit = (event) => {
		event.preventDefault();
		setDocument({
			...document,
			unitPrice: (
				parseFloat(document.unitCost) * parseInt(document.qty)
			).toFixed(2),
		});
		let upload = [];
		upload.push(document);
		intoOfficeCollection(upload);
	};

	const handleSearchTermChange = (event) => {
		event.preventDefault();
		setSearchTerm(event.target.value);
	};

	const handleSearch = async (event) => {
		event.preventDefault();
		setReplen(true);
		const result = await replenQuery(searchTerm);
		setResults(result);
	};

	const handleReplenChoice = (props) => {
		// take the $ sign of currency fields
		if (props.min || props.max) {
			if (props.min.includes("$")) {
				props.min = props.min.replace("$", "");
			}
			if (props.max.includes("$")) {
				props.max = props.max.replace("$", "");
			}
		} else {
			props.min = "";
			props.max = "";
		}

		if (props.unitCost) {
			props.unitCost = props.unitCost.replace("$", "");
		}

		setDocument({
			...document,
			...props,
			msku: "",
		});
		setReplen(false);
	};

	const handleDateChange = (e) => {
		e.preventDefault();
		const dateParts = e.target.value.split("-");
		// remove leading 0s from month
		if (dateParts[1].startsWith("0")) {
			dateParts[1] = dateParts[1].substring(1);
		}
		// remove leading 0s from day
		if (dateParts[2].startsWith("0")) {
			dateParts[2] = dateParts[2].substring(1);
		}

		const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
		setDocument({ ...document, date: formattedDate });
	};

	return (
		<>
			<h1 className="mt-5">Replenish Inventory</h1>
			<Container className="mt-3 p-3">
				<Form onSubmit={handleSearch} className="mb-2 p-3 w-50 mx-auto">
					<Input
						type="text"
						name="search"
						id="search"
						onChange={handleSearchTermChange}
					/>

					<Button className="btn-turtle_4 mx-1 mt-2" type="submit">
						Search
					</Button>
					<Button
						className="btn-danger mx-1 mt-2"
						type="button"
						onClick={() => setReplen(false)}>
						Cancel
					</Button>
				</Form>
				{replen ? (
					<>
						{results ? (
							results.map((result, idx) => (
								<CondensedTabView
									key={idx}
									props={result}
									handleClick={handleReplenChoice}
								/>
							))
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</Container>
			<Container fluid="sm" className="mt-3 p-3">
				<Form
					onSubmit={handleSubmit}
					className="mx-auto mt-2 p-3 w-25 bg-light">
					<Label htmlFor="amzProductTitle">Description</Label>
					<Input
						id="amzProductTitle"
						name="amzProductTitle"
						type="text"
						value={document.amzProductTitle}
						onChange={handleChange}
					/>
					<Label htmlFor="amzLink">Link</Label>
					<Input
						id="amzLink"
						name="amzLink"
						type="text"
						value={document.amzLink}
						onChange={handleChange}
					/>

					<Label htmlFor="upc">UPC</Label>
					<Input
						id="upc"
						name="upc"
						type="text"
						value={document.upc}
						onChange={handleChange}
					/>

					<Label htmlFor="asin">ASIN</Label>
					<Input
						id="asin"
						name="asin"
						type="text"
						value={document.asin}
						onChange={handleChange}
					/>

					<Label htmlFor="msku">MSKU</Label>
					<Input
						name="msku"
						type="text"
						value={document.msku}
						onChange={handleChange}
					/>

					<Label htmlFor="supplier">Supplier</Label>
					<Input
						id="supplier"
						name="supplier"
						type="text"
						value={document.supplier}
						onChange={handleChange}
					/>

					<Label htmlFor="date">Date</Label>
					<Input
						id="date"
						name="date"
						type="date"
						onChange={handleDateChange}
					/>

					<Label htmlFor="warehouse">Warehouse</Label>
					<Input
						id="warehouse"
						name="warehouse"
						type="text"
						value={document.warehouse}
						onChange={handleChange}
					/>

					<Label htmlFor="list">List</Label>
					<Input
						id="list"
						name="list"
						type="text"
						value={document.list}
						onChange={handleChange}
					/>

					<Label htmlFor="unitCost">Cost</Label>
					<Input
						id="unitCost"
						name="unitCost"
						type="text"
						value={document.unitCost}
						onChange={handleChange}
					/>

					<Label htmlFor="min">Min Price</Label>
					<Input
						id="min"
						sm={10}
						name="min"
						type="text"
						value={document.min}
						onChange={handleChange}
					/>

					<Label htmlFor="max">Max Price</Label>
					<Input
						id="max"
						sm={10}
						name="max"
						type="text"
						value={document.max}
						onChange={handleChange}
					/>

					<Label htmlFor="salesTaxPaid">Sales Tax</Label>
					<Input
						id="salesTaxPaid"
						sm={10}
						name="salesTaxPaid"
						type="text"
						value={document.salesTaxPaid}
						onChange={handleChange}
					/>

					<Label htmlFor="qty">Qty</Label>
					<Input
						id="qty"
						name="qty"
						defaultValue={0}
						type="text"
						onChange={(event) =>
							setDocument({
								...document,
								[event.target.name]: parseInt(event.target.value),
							})
						}
					/>

					<Label htmlFor="initials">Initials</Label>
					<Input
						id="initials"
						sm={10}
						name="initials"
						type="text"
						value={document.initials}
						onChange={handleChange}
					/>

					<Label htmlFor="notes">Notes</Label>
					<Input
						id="notes"
						name="notes"
						type="text"
						value={document.notes}
						onChange={handleChange}
					/>

					<Button type="submit" className="mt-2">
						Submit
					</Button>
				</Form>
			</Container>
		</>
	);
};
