import React, {
	createContext,
	useState,
	useContext,
	useReducer,
	useEffect,
} from "react";
import { toast } from "react-toastify";
import UserContext from "./UserContext";
import MongoService from "../services/MongoService";
import getStoredProducts from "../pages/OfficeCollection/GetStoredProducts";

export const MongoContext = createContext();

const initialState = {
	products: [],
	stagedProducts: [],
	found: [],
	shipping: [],
};

const productReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_PRODUCTS":
			console.log(
				"🚀 ~ file: MongoContext.js:35 ~ productReducer ~ action",
				action.payload
			);
			return {
				...state,
				products: state.products.map((product) =>
					product._id.toString() === action.payload._id
						? { ...product, ...action.payload }
						: product
				),
			};

		case "SET_FOUND":
			return {
				...state,
				found: action.payload,
			};
		case "SET_SHOWING":
			return {
				...state,
				showing: action.payload,
			};
		case "GET_PRODUCTS":
			return {
				...state,
				products: action.payload,
			};
		case "GET_STAGED_PRODUCTS":
			return {
				...state,
				stagedProducts: action.payload,
			};
		case "DELETE_PRODUCT":
			return {
				...state,
				products: state.products.filter(
					(product) => product._id.toString() !== action.payload.toString()
				),
			};
		default: {
			return state;
		}
	}
};

export const MongoContextProvider = ({ children }) => {
	const [updates, setUpdates] = useState([]);
	const [shipping, setShipping] = useState([]);
	const [state, dispatch] = useReducer(productReducer, initialState);
	const { user, isAdmin, app } = useContext(UserContext);

	useEffect(() => {
		var shippingPlan = JSON.parse(localStorage.getItem("shippingPlan") || "[]");
		setShipping(shippingPlan);
	}, []);

	/**
	 * function to check if Object is already in array,
	 * replace if so.
	 */
	function addToUpdates(_id, changes) {
		console.log("🚀 ~ file: MongoContext.js:65 ~ addToUpdates ~ _id:", _id);
		console.log(
			"🚀 ~ file: MongoContext.js:65 ~ addToUpdates ~ changes:",
			changes
		);
		let newArray = [...updates];
		const idx = updates.findIndex((e) => e._id === _id);
		if (idx > -1) {
			let valueToUpdate = newArray[idx];
			// loop through the changes in the updated document
			Object.keys(changes).forEach((newValue) => {
				// if value exists in object, it'll be replaced, if not,it'll be added.
				valueToUpdate[newValue] = changes[newValue];
			});
			// doc already in array replace with new doc
			newArray[idx] = valueToUpdate;
			dispatch({ type: "UPDATE_PRODUCTS", payload: valueToUpdate });
			setUpdates(newArray);
		} else {
			let newUpdate = { _id, ...changes };
			setUpdates((prevState) => [...prevState, newUpdate]);
		}
		// save updates to local storage
		localStorage.setItem("updateProgress", JSON.stringify(updates));
	}

	function clearUpdates() {
		setUpdates([]);
		setShipping([]);
		localStorage.setItem("updateProgress", JSON.stringify([]));
		localStorage.setItem("shippingPlan", JSON.stringify([]));
	}
	// replicates what addToUpdates does, I feel like there is a better way
	// to implement this.
	function addToShipPlan(id, changes) {
		let newArray = [...shipping];
		const idx = shipping.findIndex((e) => e._id === id);
		if (idx > -1) {
			// doc already in array replace with new doc
			newArray[idx] = changes;
			setShipping(newArray);
		} else {
			setShipping((prevState) => [...prevState, changes]);
		}
		// save shipping plan to local storage
		localStorage.setItem("shippingPlan", JSON.stringify(shipping));
	}

	// push array of changes to be made to function in Mongo
	async function pushToOffice() {
		try {
			updates.forEach((product) => {
				product.unitsSDR = product.unitsReceived;
			});
			var response = await MongoService.pushToOffice(user, updates);
			setUpdates([]);
			localStorage.setItem("updateProgress", JSON.stringify([]));
			setShipping([]);
			localStorage.setItem("shippingPlan", JSON.stringify([]));
			toast.success("Update Success!");
		} catch (err) {
			toast.error("Update failed :(");
		}
	}

	async function pushSingleDocToOffice(input) {
		try {
			var response = await MongoService.pushSingleDocOffice(user, input);
			toast.success("Upload Success!");
			return response;
		} catch (err) {
			toast.error("Upload failed :(");
		}
	}

	// commit the products from the staged collection to the master collection
	async function pushFromStagedToMaster() {
		if (isAdmin) {
			try {
				var response = await user.functions.stagedToMaster();
				if (response) {
					toast.success("Action Success!");
				}
			} catch (err) {
				toast.error("An error occured");
			}
		} else {
			toast.error("You are not authorized to perform this action");
		}
	}

	async function pushToMaster() {
		try {
			var response = await user.functions.updateMaster(updates);
			setUpdates([]);
			if (response) {
				toast.success("Update Success!");
			}
		} catch (err) {
			toast.error("An error occured");
		}
	}

	//TODO: create a functions folder to separate functions like getData MAYBE
	const getData = async () => {
		try {
			const allData = await user.functions.allOfficeItems();
			var updateProgress = JSON.parse(
				localStorage.getItem("updateProgress") || "[]"
			);
			var data = getStoredProducts(updateProgress, allData);
			dispatch({
				type: "GET_PRODUCTS",
				payload: data.length > 0 ? data : allData,
			});
			return allData;
		} catch (err) {
			console.log(err);
		}
	};
	// insert docs into office collection
	async function intoOfficeCollection(array) {
		try {
			const inserted = await user.functions.insertToOffice(array);
			// console.log(inserted);
			toast.success("Import Successful!");
		} catch (error) {
			toast.error("Something went wrong :(");
			console.error(error);
		}
	}

	const updateOfficeProductInfo = async (product) => {
		try {
			const updated = await MongoService.updateOfficeProductInfo(user, product);
			let newArray = [...state.products];
			const idx = state.products.findIndex(
				(e) => e._id.toString() === product._id.toString()
			);
			if (idx > -1) {
				let valueToUpdate = newArray[idx];
				console.log(valueToUpdate);
				// loop through the changes in the updated document
				Object.keys(product).forEach((newValue) => {
					// if value exists in object, it'll be replaced, if not,it'll be added.
					valueToUpdate[newValue] = product[newValue];
				});
				// doc already in array replace with new doc
				newArray[idx] = valueToUpdate;
				dispatch({ type: "UPDATE_PRODUCTS", payload: valueToUpdate });
				// dispatch({ type: 'UPDATE_PRODUCTS', payload: product });
				toast.success("Update Successful!");
				return updated;
			}
		} catch (err) {
			toast.error("Update failed");
			console.error(err);
		}
	};

	const deleteOfficeProduct = async (product) => {
		try {
			// console.log(product);
			const deletion = await user.functions.deleteOfficeProduct(product);
			dispatch({ type: "DELETE_PRODUCT", payload: product });
			return deletion;
		} catch (err) {
			console.error(err);
		}
	};

	// TODO: change this to dispatch to the reducer and return results to found
	const searchLocalProducts = async (search) => {
		var searchLower = search.toLowerCase();
		var results;
		try {
			var found = state.products.filter((product) => {
				if (
					Object.values(product).some((value) => {
						if (typeof value === "string") {
							return value.toLowerCase().includes(searchLower);
						} else {
							return false;
						}
					})
				) {
					// dispatch({ type: "SET_FOUND", payload: product });
					return product;
				}
			});
			results = found;
		} catch (err) {
			console.error(err);
		}
		// console.log(results);
		// dispatch({ type: "SET_FOUND", payload: results });
		return results;
	};

	const getStagedProducts = async () => {
		try {
			// use only one state value for both products and stagedProducts
			const staged = await MongoService.getStagedProducts(user);
			dispatch({ type: "GET_STAGED_PRODUCTS", payload: staged });
			return staged;
		} catch (err) {
			console.error(err);
		}
	};

	const searchStagedProducts = async (search) => {
		var searchLower = search.toLowerCase();
		var results;
		try {
			var found = state.stagedProducts.filter((product) => {
				if (
					Object.values(product).some((value) => {
						if (typeof value === "string") {
							return value.toLowerCase().includes(searchLower);
						} else {
							return false;
						}
					})
				) {
					// dispatch({ type: "SET_FOUND", payload: product });
					return product;
				}
			});
			results = found;
		} catch (err) {
			console.error(err);
		}
		// console.log(results);
		// dispatch({ type: "SET_FOUND", payload: results });
		return results;
	};

	const moveToStaged = async (_id) => {
		try {
			var idString = { _id: _id.toString() };
			const moved = await user.functions.officeToStaged(idString);
			dispatch({ type: "DELETE_PRODUCT", payload: _id });
			return moved;
		} catch (err) {
			console.error(err);
			toast.error("Something went wrong :(");
		}
	};

	async function getMongoCol() {
		const client = await app.currentUser.mongoClient("mongodb-atlas");
		return client.db("ArbitrageTest").collection("office_collection");
	}

	const watchOfficeCol = async () => {
		const office = await getMongoCol();
		const stream = office.watch();

		for await (const change of stream) {
			// if there is an update in the DB, update the product in the array
			if (change.operationType === "update") {
				const updatedProduct = change.fullDocument;
				updatedProduct._id = updatedProduct._id.toString();
				dispatch({ type: "UPDATE_PRODUCTS", payload: updatedProduct });
			}
		}
	};

	const queryMasterCol = async (search) => {
		try {
			const data = await user.functions.queryMaster(search);
			return data;
		} catch (err) {
			console.error(err);
		}
	};

	const replenQuery = async (search) => {
		try {
			const data = await user.functions.replenQuery(search);
			return data;
		} catch (err) {
			console.error(err);
		}
	};

	const getImgUrl = async (asin) => {
		try {
			const url = await MongoService.getImgUrl(asin);
			return url;
		} catch (err) {
			console.error(err);
		}
	};

	const values = {
		...state,
		setUpdates,
		addToUpdates,
		clearUpdates,
		pushToOffice,
		pushSingleDocToOffice,
		intoOfficeCollection,
		pushToMaster,
		pushFromStagedToMaster,
		updateOfficeProductInfo,
		deleteOfficeProduct,
		searchLocalProducts,
		searchStagedProducts,
		queryMasterCol,
		replenQuery,
		getStagedProducts,
		moveToStaged,
		getData,
		setShipping,
		addToShipPlan,
		shipping,
		updates,
		watchOfficeCol,
		getImgUrl,
	};

	return (
		<MongoContext.Provider value={values}>{children}</MongoContext.Provider>
	);
};

export default MongoContext;
