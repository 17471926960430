import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

export function Login() {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const { Login, fetchUser, user } = useContext(UserContext);
	const location = useLocation();
	const navigate = useNavigate();

	const redirectNow = () => {
		const redirectTo = location.search.replace("?redirectTo=", "");
		navigate(redirectTo ? redirectTo : "/");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// if user is logged in, take them to Master collection
			await Login(username, password).then((response) => {
				if (response) {
					redirectNow();
				}
			});
		} catch (error) {
			toast.error("Invalid username or password");
			alert(error);
		}
	};
	const loadUser = async () => {
		if (!user) {
			const fetchedUser = await fetchUser();
			if (fetchedUser) {
				// Redirecting them once fetched.
				redirectNow();
			}
		}
	};

	useEffect(() => {
		loadUser();
	}, []);

	return (
		<div className="container-xxl">
			<h2 className="my-5">Login</h2>
			<Form className="my-3" onSubmit={handleSubmit}>
				<Form.Group className="mb-3" controlId="formItemName">
					<Form.Label>
						<strong>Username</strong>
					</Form.Label>
					<Form.Control
						className="mx-auto w-50 text-center"
						type="text"
						onChange={(e) => setUsername(e.target.value)}
					/>
					<Form.Label>
						<strong>Password</strong>
					</Form.Label>
					<Form.Control
						className="mx-auto w-50 text-center"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className="row mx-auto w-25">
						<Button className="col-auto mx-auto mt-2" type="submit">
							Log In
						</Button>
					</div>
				</Form.Group>
			</Form>
		</div>
	);
}

export default Login;
