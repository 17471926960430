import { React, memo, useState, useContext } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import MongoContext from '../../context/MongoContext';
//TODO: change file name to Master, this will be for admin views.
export const AdminProduct = memo(function MasterProduct({ props, isEdit }) {
	//TODO: Clean this mess up.
	// this is a VERY UGLY solution, but it works. This will need to be cleaned up
	const [productInfo, setProductInfo] = useState({
		date: props.date,
		attributes: props.attributes,
		min: props.min,
		max: props.max,
		amzProductTitle: props.amzProductTitle,
		supplier: props.supplier,
		productLink: props.productLink,
		amzLink: props.amzLink,
		asin: props.asin,
		qty: props.qty,
		totalBuyPrice: props.totalBuyPrice,
		unitCost: props.unitCost,
		list: props.list,
		salesTaxPaid: props.salesTaxPaid,
		warehouse: props.warehouse,
		orderNumber: props.orderNumber,
		msku: props.msku,
		upc: props.upc,
		unitsReceived: props.unitsReceived,
		unitsSDR: props.unitsSDR,
		notes: props.notes,
	});
	const { addToUpdates } = useContext(MongoContext);

	const handleEdit = (e) => {
		const newData = { ...productInfo };
		let _id = props._id;

		newData[e.target.id] = e.target.value;

		setProductInfo(newData);
		addToUpdates(_id, newData);
	};

	return (
		<tr>
			{Object.keys(productInfo).map((keyName, idx) => {
				if (keyName !== '_id') {
					if (keyName === 'productLink' || keyName === 'amzLink') {
						return (
							<td>
								<div className='overflow-hidden' style={{ width: '3vw' }}>
									<a
										className='link-info'
										target='_blank'
										rel='noopener noreferrer'
										href={productInfo[keyName]}>
										{productInfo[keyName] === undefined ? (
											'n/a'
										) : (
											<BiLinkExternal />
										)}
									</a>
								</div>
							</td>
						);
					}
					// if isEdit is true, then we render <input> tags
					return isEdit ? (
						<td>
							<input
								key={idx}
								id={keyName}
								type='text'
								value={productInfo[keyName]}
								onChange={handleEdit}
							/>
						</td>
					) : (
						<td>{productInfo[keyName]}</td>
					);
				}
			})}
		</tr>
	);
});
