import React from "react";
import { Col, Row, Button } from "reactstrap";
import getImgUrl from "../utils/getImgUrl";

export default function CondensedTabView({ props, handleClick }) {
	// not using this, but keep it just in case
	const limitLink = (link) => {
		if (!link) {
			return "";
		}
		if (link.length > 50) {
			return link.slice(0, 50) + "...";
		} else {
			return link;
		}
	};

	return (
		<Row className="bg-light mt-2 p-2 h-100 border border-2 rounded border-turtle_4">
			<Col
				style={{
					width: "7em",
					height: "8em",
				}}
				className="text-start">
				<img
					className="img-thumbnail text-dark p-0 align-items-center"
					style={{
						objectFit: "contain",
						width: "8em",
						height: "8em",
					}}
					src={props.imgUrl}
					alt="NOT FOUND"
					loading="lazy"></img>
			</Col>
			<Col sm={11}>
				<Row className="mt-2 fw-bold">{props.amzProductTitle}</Row>
				<Row className="mt-3 fs-5">
					<Col sm={3} className="text-start">
						<Row>{props.supplier}</Row>
						<Row>{props.unitCost}</Row>
					</Col>
					<Col sm={4}>
						{props.asin}/{props.upc}
					</Col>
					<Col sm={3} className="text-end">
						{props.msku}
					</Col>
					<Col sm={2}>
						<Button onClick={() => handleClick(props)}>Replen</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
