/* eslint-disable jsx-a11y/img-redundant-alt */
/**
 * @description a component to display the results from a query
 * to the master sheet database, which may be renamed, since it
 * won't be a sheet anymore.
 */
import { React, memo, useContext, useState, useEffect } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { MdDeleteSweep } from 'react-icons/md';
import { AiFillEdit } from 'react-icons/ai';
import { animateCSS } from '../../components/AddAnimation';
import {
	UncontrolledDropdown,
	option,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { OfficeProductEditMode } from './OfficeProductEditMode';
import 'animate.css';
import MongoContext from '../../context/MongoContext';
import UserContext from '../../context/UserContext';

/**
 * memo is here for performance, the component "memorizes" the props
 * and will re-render only when they change.
 */
export const OfficeProduct = memo(function OfficeProduct({
	props,
	deleteProduct,
}) {
	const { addToUpdates, addToShipPlan, products } = useContext(MongoContext);
	const { user, isAdmin } = useContext(UserContext);

	const [productInfo, setProductInfo] = useState({
		unitsReceived: props.unitsReceived,
		unitsSDR: props.unitsSDR,
		notes: props.notes,
		modBy: props.modBy,
		warehouse: props.warehouse,
	});
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		setProductInfo({
			unitsReceived: props.unitsReceived,
			unitsSDR: props.unitsSDR,
			notes: props.notes,
			modBy: props.modBy,
			warehouse: props.warehouse,
		});
	}, [props]);

	// this is a temporary solution for live updates
	const pendingChange = () => {
		const ogItem = products.find(
			(item) => item._id.toString() === props._id.toString()
		);

		if (ogItem !== undefined) {
			if (
				ogItem.unitsReceived !== productInfo.unitsReceived ||
				ogItem.unitsSDR !== productInfo.unitsSDR ||
				ogItem.notes !== productInfo.notes
			) {
				return true;
			}
		}
		return false;
	};
	// change handler will call updateProductInfo -> updateProductInfo
	// call addToUpdates.
	const updateProductInfo = (event) => {
		const newData = { ...productInfo, modBy: user.customData.firstName };
		let units;
		let _id = props._id.toString();

		if (event.target.id === 'notes') {
			newData[event.target.id] = event.target.value;
			setProductInfo(newData);
			addToUpdates(_id, newData);
			return;
		}
		/**
		 * Checking if the units being changed are number values,
		 * if not then we can just insert it as string
		 */
		if (isNaN(parseInt(event.target.value))) {
			newData[event.target.id] = event.target.value;
		} else {
			units = parseInt(event.target.value);
			newData[event.target.id] = units;
		}
		setProductInfo(newData);
		addToUpdates(_id, newData);

		if (event.target.id === 'unitsReceived') {
			var toShip = props;
			toShip._id = toShip._id.toString();
			toShip.unitsReceived = units;
			addToShipPlan(_id, toShip);
		}
	};

	const inputReceived = (event) => {
		// regex to check if input is a number
		const reg = /^[0-9\b]+$/;

		// if value isn't blank test regex
		if (event.target.value !== '' || reg.test(event.target.value)) {
			// setReceived(event.target.value);
			// handleReceivedChange(event.target.value);
			updateProductInfo(event);
		} else {
			event.target.value = 0;
			alert('Received must be a value between 0 and 99');
		}
	};
	const sdrChanged = (event) => {
		// regex to check if input is a number
		const reg = /^[0-9\b]+$/;

		// if value isnt blank test regex
		if (
			event.target.value <= productInfo.unitsReceived &&
			reg.test(event.target.value)
		) {
			// handleSdrChange(event.target.value);
			updateProductInfo(event);
		} else {
			event.target.value = 0;
			alert('SDR value must be a value less than or equal to Received');
		}
	};

	//TODO: may have to implement useEffect hook
	return editMode && isAdmin ? (
		<OfficeProductEditMode
			props={props}
			editMode={editMode}
			setEditMode={setEditMode}
		/>
	) : (
		<div
			className="container p-2 border-top border-bottom border-dark text-dark overflow-hidden position-relative animate__animated animate__fadeInRight"
			id={props._id}>
			<button
				className="position-absolute text-center bottom-0 end-0 btn btn-sm"
				type="button"
				onClick={() =>
					animateCSS(props._id, 'fadeOutRight').then(() =>
						deleteProduct(props._id)
					)
				}>
				<MdDeleteSweep className="fs-1 text-danger" />
			</button>
			<button
				className={
					isAdmin
						? 'position-absolute text-center bottom-0 start-0 btn btn-sm'
						: 'd-none'
				}
				disabled={isAdmin ? false : true}
				type="button"
				onClick={() => setEditMode(true)}>
				<AiFillEdit className="fs-1 text-dark" />
			</button>
			<div className="row mt-4" style={{ maxHeight: 210 + 'px' }}>
				<div className="col-9">
					<div className="row mb-3">
						<h6 className="col-3 font-monospace text-start fw-bold my-4">
							{props.date}
						</h6>
						<div className="col-6 fw-bold text-danger my-4 animate__animated animate__pulse animate__infinite">
							{pendingChange() ? 'PENDING CHANGE FROM OTHER USER' : null}
						</div>
					</div>
					<div
						className="row mb-2 overflow-auto"
						style={{ maxHeight: 70 + 'px' }}>
						<div className="col-4 text-start">
							<strong>Min: $</strong>
							{props.min}
						</div>
						<div className="col-4 text-start">
							<strong>ASIN: </strong>
							<a
								className="link-info"
								target="_blank"
								rel="noopener noreferrer"
								href={props.amzLink}>
								{props.asin}
							</a>
						</div>
						<div className="col-4 text-start">
							<strong>Qty: </strong> {props.qty}
						</div>
					</div>
					<div className="row">
						<div className="col-4 text-start">
							<strong>Max: $</strong>
							{props.max}
						</div>
						<div className="col-4 text-start">
							<strong>Attributes: </strong>
							{props.attributes}
						</div>
						<div className="col-4 text-start">
							<strong>Unit Cost: </strong>
							{props.unitCost}
						</div>
					</div>
				</div>
				<div className="col-3 text-center" style={{ height: 210 + 'px' }}>
					<img
						className="img-thumbnail text-dark p-0 align-items-center"
						style={{
							objectFit: 'contain',
							width: 240 + 'px',
							height: 220 + 'px',
						}}
						src={props.imgUrl}
						alt="NO IMAGE FOUND"
						loading="lazy"></img>
				</div>
			</div>
			<div className="row my-3">
				<div className="col-3 text-start">
					<strong>Warehouse: </strong>
					<select
						id="warehouse"
						className="btn btn-sm btn-turtle_4 fw-bold fs-6 dropdown-toggle"
						value={productInfo.warehouse}
						onChange={(e) => updateProductInfo(e)}
						selected={productInfo.warehouse}>
						<option value="VENUS">VENUS</option>
						<option value="Alfa">Alfa</option>
						<option value="Bravo">Bravo</option>
						<option value="Charlie">Charlie</option>
						<option value="Delta">Delta</option>
						<option value="Echo">Echo</option>
						<option value="Foxtrot">Foxtrot</option>
						<option value="Gulf">Gulf</option>
						<option value="Hotel">Hotel</option>
						<option value="India">India</option>
						<option value="Juliet">Juliet</option>
						<option value="Kilo">Kilo</option>
						<option value="Lima">Lima</option>
						<option value="Mike">Mike</option>
						<option value="November">November</option>
						<option value="Oscar">Oscar</option>
						<option value="Papa">Papa</option>
						<option value="Quebec">Quebec</option>
						<option value="Romeo">Romeo</option>
						<option value="Sierra">Sierra</option>
						<option value="Tango">Tango</option>
						<option value="Uniform">Uniform</option>
						<option value="Victor">Victor</option>
						<option value="Whiskey">Whiskey</option>
						<option value="Xray">Xray</option>
						<option value="Yankee">Yankee</option>
						<option value="Zulu">Zulu</option>
					</select>
				</div>
				<div className="col-3 text-start overflow-auto">
					<strong>Product Link: </strong>
					<a
						className="link-info"
						target="_blank"
						rel="noopener noreferrer"
						href={props.productLink}>
						{props.productLink === undefined ? 'n/a' : <BiLinkExternal />}
					</a>
				</div>
				<div className="col-3 text-start">
					<strong>MSKU: </strong>
					{props.msku}
				</div>
				<div className="col-3 d-flex position-relative">
					<strong className="position-absolute start-0">Rcvd: </strong>
					<input
						id="unitsReceived"
						type="number"
						className="w-25 position-absolute end-50"
						value={productInfo.unitsReceived}
						onChange={inputReceived}
					/>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-3 text-start">
					<strong>Supplier: </strong>
					{props.supplier}
				</div>
				<div className="col-3 text-start">
					<strong>Modified By: </strong>
					{props.modBy}
				</div>
				<div className="col-3 text-start">
					<strong>UPC: </strong>
					{props.upc}
				</div>
				<div className="col-3 d-flex position-relative">
					<strong className="position-absolute start-0">S/D/R:</strong>
					<input
						id="unitsSDR"
						type="number"
						className="w-25 position-absolute end-50"
						value={productInfo.unitsSDR}
						onChange={sdrChanged}
					/>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-6 text-start">
					<strong>Description: </strong>
					{props.amzProductTitle}
				</div>
				<div className="col-6 text-start">
					<strong>Notes: </strong>
					<textarea
						id="notes"
						className="align-middle form-control w-75"
						type="text"
						defaultValue={productInfo.notes}
						onChange={(e) => updateProductInfo(e)}
					/>
				</div>
			</div>
		</div>
	);
});
