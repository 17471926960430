import { useState, useContext, useLayoutEffect, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import CreateShippingPlan from "./CreateShippingPlan";
import ClearChangesBtn from "./ClearChangesBtn";
import { OfficeProduct } from "./OfficeProductModel";
import { StagedProduct } from "../../models/StagedProductModel";
import ToggleSwitch from "./ToggleSwitch";
import DownloadOfficeCollection from "./DownloadOfficeCollection";
import UserContext from "../../context/UserContext";
import MongoContext from "../../context/MongoContext";
import TablePagination from "../../components/TablePagination";
import PaginationFooter from "../../components/PaginationFooter";
import InstructionsCard from "./InstructionsCard";
import ConfirmationModal from "../../components/ConfirmationModal";
import UpdateProgressSideBar from "./UpdateProgressSideBar";
import "animate.css";
import { toast } from "react-toastify";

function OfficeCatalog() {
	// TODO: going to have to implement a useReducer to avoid all the useStates
	const [page, setPage] = useState(1);
	const [items, setItems] = useState([]);
	const [query, setQuery] = useState("");
	const [confirm, setConfirm] = useState(false);
	const [officeView, setOfficeView] = useState(true);

	const { user, isAdmin } = useContext(UserContext);
	const {
		pushToOffice,
		pushFromStagedToMaster,
		getData,
		getStagedProducts,
		products,
		searchLocalProducts,
		stagedProducts,
		searchStagedProducts,
		moveToStaged,
		watchOfficeCol,
	} = useContext(MongoContext);

	const { slice, range } = TablePagination(items, page, 100);
	// const { slice, range } = TablePagination(products, page, 100);

	/**
	 * @returns any data that matches MongoDb query from user
	 */
	const getProductData = async () => {
		try {
			const allData = await getData();
			setItems(allData);
		} catch (err) {
			console.log(err);
		}
	};

	// get all data from staged DB and replace the items array
	const getStagedData = async () => {
		try {
			setOfficeView(false);
			const stagedData = await getStagedProducts();
			setItems(stagedData);
		} catch (err) {
			console.log(err);
		}
	};

	const switchView = async (e) => {
		e.preventDefault();
		setOfficeView(!officeView);
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		setItems([]);
		// submit button will push changes to office DB if in office view
		if (officeView) {
			pushToOffice().then(() => {
				getProductData();
				setConfirm(!confirm);
			});
		}
		// if in staged view, push changes to master DB
		else if (!officeView) {
			pushFromStagedToMaster().then(() => {
				getStagedProducts();
			});
			setConfirm(!confirm);
		}
	};

	const stagedToOffice = async (_id) => {
		try {
			var toUndo = { _id: _id.toString() };
			console.log(toUndo);
			const confirmation = await user.functions.stagedToOffice(toUndo);
			let updatedItems = items.filter((item) => {
				return item._id !== _id;
			});
			setItems(updatedItems);
			console.log(confirmation);
		} catch (err) {
			console.error(err);
		}
	};
	/**
	 * @desc search through array received from Mongo, resetting if query is empty.
	 *  TODO: Set the "else" portion of the code to a "Clear Filters" button in
	 *  a separate function.
	 * 	*/

	// Users should submit changes before switching views
	useEffect(() => {
		//TODO: change this to get it's state from the context values
		if (officeView === true && products.length === 0) {
			getProductData();
		} else if (officeView === true && products.length > 0) {
			setItems(products);
		} else {
			getStagedData();
		}
		// if (officeView) {
		// 	getProductData();
		// } else if (!officeView) {
		// 	getStagedData();
		// }
		watchOfficeCol();
	}, [officeView, products.length]);

	async function SearchOfficeCollection(e) {
		e.preventDefault();
		var found = await searchLocalProducts(query);
		if (found.length < 0) {
			toast.warning("No Search Results Found");
		} else {
			setItems(found);
		}
	}

	async function SearchStagedCollection(e) {
		e.preventDefault();
		var found = await searchStagedProducts(query);
		if (found.length < 0) {
			toast.warning("No Search Results Found");
		} else {
			setItems(found);
		}
	}

	async function SearchCurrentView(e) {
		e.preventDefault();
		if (officeView) {
			SearchOfficeCollection(e);
		} else {
			SearchStagedCollection(e);
		}
	}

	// clear search results and search text box
	function clearSearch(e) {
		e.preventDefault();
		setQuery("");
		if (officeView) {
			setItems(products);
		} else {
			setItems(stagedProducts);
		}
		// getProductData();
	}

	useLayoutEffect(() => {
		getProductData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="container position-relative">
			{/* <UpdateProgressSideBar /> */}
			<ConfirmationModal
				modal={confirm}
				setModal={() => setConfirm(!confirm)}
				onConfirm={handleSubmit}
				message="Are you sure you want to submit changes?"
				title="Submit Changes"
			/>
			<section className="container p-0 sticky-top">
				<form className="my-3 row mx-auto" onSubmit={SearchCurrentView}>
					<div className="col-lg-2 col-12 mt-3">
						<ToggleSwitch view={officeView} setView={switchView} />
					</div>
					<div className="col-lg-10 col-12 mx-auto">
						<div className="row mx-auto">
							<input
								className="col-lg-8 col-12 mt-2 text-center fs-6"
								type="search"
								value={query}
								placeholder="Description, UPC, or ASIN"
								onChange={(e) => setQuery(e.target.value)}
							/>
							<div className="col-lg-4 col-12 text-lg-start text-center p-0 rounded">
								<button className="btn btn-turtle_4 mt-3 mx-1" type="submit">
									{/* <BsSearch /> */}
									Search
								</button>
								<button
									className="btn btn-turtle_4 mt-3 mx-1"
									type="button"
									onClick={(e) => clearSearch(e)}>
									{/* <MdClear /> */}
									Clear
								</button>
							</div>
						</div>
					</div>
				</form>
				<div className="container">
					<div className="row align-items-center justify-content-center mb-1">
						<h4 className="col-lg-3 col-12 text-lg-start text-center text-dark mx-auto ms-1">
							{items.length} ENTRIES FOUND
						</h4>
						{isAdmin && officeView ? (
							<DownloadOfficeCollection collection={items} />
						) : (
							<></>
						)}
						<ClearChangesBtn />
						<CreateShippingPlan />
						<button
							className="col-4 w-auto btn btn-turtle_4 mx-1"
							type="button"
							onClick={() => setConfirm(!confirm)}>
							Submit
						</button>
					</div>
				</div>
			</section>
			<section>
				<div className="">
					{slice.map((doc, index) => {
						return officeView ? (
							<OfficeProduct
								key={doc._id}
								props={doc}
								deleteProduct={moveToStaged}
							/>
						) : (
							<StagedProduct
								key={doc._id}
								props={doc}
								undoDelete={stagedToOffice}
							/>
						);
					})}
				</div>

				<PaginationFooter
					range={range}
					slice={slice}
					setPage={setPage}
					page={page}
				/>
			</section>
		</div>
	);
}

export default OfficeCatalog;
