import React, { useContext } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MongoContext from '../context/MongoContext';

const SubmitTimeOutModal = ({
	setShowModal,
	showModal,
	handleContinue,
	remainingTime,
}) => {
	const { pushToOffice } = useContext(MongoContext);

	const handleSubmit = () => {
		pushToOffice();
		setShowModal(false);
	};

	return (
		<Modal isOpen={showModal} onClosed={handleContinue}>
			<ModalHeader>Are you still there?</ModalHeader>
			<ModalBody>It's been a while, ready to submit your changes?</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={handleSubmit}>
					Submit
				</Button>
				<Button color='danger' onClick={handleContinue}>
					Not Yet
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default SubmitTimeOutModal;
