import React, { useContext } from "react";
import MongoContext from "../../context/MongoContext";
import { useCSVDownloader } from "react-papaparse";
import XLSX from "xlsx";
import { writeFileXLSX } from "xlsx";
import { Button } from "reactstrap";

function CreateShippingPlan() {
	// const { CSVDownloader, Type } = useCSVDownloader();
	const { shipping } = useContext(MongoContext);
	const calcListPrice = (maxPrice) => {
		var listPrice = parseFloat(maxPrice) - 1.0;
		return listPrice;
	};
	// Shipping plan is based off of the Received field
	const convertPlanFields = () => {
		var shippingPlan = shipping.map((field) => ({
			SKU: field.msku,
			QTY: field.unitsReceived,
			UNITS_PER_CASE: "",
			LOCATION: "",
			NOTES: "",
			"MM-DD-YYYY": "",
			EXPIRY_TEXT: "",
			TITLE: field.amzProductTitle,
			UPC: "",
		}));
		return shippingPlan;
	};

	const downloadXlsx = (e, data) => {
		e.preventDefault();
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Shipping Plan");
		XLSX.writeFile(wb, `shippingPlan-${date}-${time}.xlsx`);
	};

	const planActive = () => {
		if (shipping.length > 0) {
			return "col-3 w-auto mx-1 btn btn-turtle_4 animate__animated animate__pulse animate__infinite";
		} else {
			return "col-3 w-auto mx-1 btn btn-turtle_4 disabled";
		}
	};
	var config = {
		delimeter: ",",
		header: true,
		skipEmptyLines: true,
	};

	// variables for time of day and date used in filename
	const today = new Date();
	const date =
		today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();
	const time = today.getHours() + "." + today.getMinutes();
	return (
		// <CSVDownloader
		// 	className={planActive()}
		// 	type={Type.Button}
		// 	filename={"shipPlan" + date + "-" + time}
		// 	bom={true}
		// 	config={config}
		// 	data={convertPlanFields}>
		// 	Shipping Plan
		// </CSVDownloader>
		<Button
			className={planActive()}
			onClick={(e) => downloadXlsx(e, convertPlanFields())}>
			Shipping Plan
		</Button>
		// </div>
	);
}

export default CreateShippingPlan;
