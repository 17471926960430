/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

function TablePagination(data, page, rowsPerPage) {
	const [tableRange, setTableRange] = useState([]);
	const [slice, setSlice] = useState([]);

	useEffect(() => {
		if (data.length !== undefined) {
			const range = calculateRange(data, rowsPerPage);
			setTableRange([...range]);

			// console.log("useEffect data", data.length);
			const slice = sliceData(data, page, rowsPerPage);
			setSlice([...slice]);
		}
	}, [data, setTableRange, page, setSlice]);
	const calculateRange = (data, rowsPerPage) => {
		const range = [];
		const num = Math.ceil(data.length / rowsPerPage);
		for (let i = 1; i <= num; i++) {
			range.push(i);
		}
		return range;
	};
	const sliceData = (data, page, rowsPerPage) => {
		// console.log("data length", data.length);

		return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
	};

	return { slice, range: tableRange };
}

export default TablePagination;
