import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
// this component will be used to display instructions for the users
function InstructionsCard() {
	const [open, setOpen] = useState(false);

	return (
		<div>
			{open ? (
				<Modal size="lg" centered show={open} onHide={() => setOpen(false)}>
					<div className="modal-dialog">
						<div className="modal-content">
							<Modal.Header>
								<Modal.Title>App Instructions</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p className="modal-body">
									- Search for the UPC of the item you want to modify. <br />
									- Adjust the quantity of the item you want to modify. <br />-
									Before saving your changes, download your shipping plan <br />
									- Once "unitsSDR" and "qty" are equal, the item will
									automatically move to the "Staged" database, and will await
									admin approval. <br />
									- Changes will not be saved until you click the save changes
									button. <br />- The page will refresh, and your changes will
									be reflected <br />
									- If something is moved to the "Staged" database by mistake,
									you can hit the return button and your product will be moved
									back to the original database <br />
									- Ensure that you change the "unitsSDR" of the returned item
									before making any more changes, or it will be moved back to
									the "Staged" database as soon as any more changes are
									submitted. <br />
								</p>
								<button
									className="btn btn-secondary"
									type="button"
									onClick={() => setOpen(false)}>
									close
								</button>
							</Modal.Body>
						</div>
					</div>
				</Modal>
			) : (
				<button
					className="btn btn-turtle_4 mx-1"
					type="button"
					data-bs-target="#instructionsModal"
					data-bs-toggle="modal"
					onClick={() => setOpen(true)}>
					{/* <AiFillQuestionCircle /> */}
					Help
				</button>
			)}
		</div>
	);
}

export default InstructionsCard;
